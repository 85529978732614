import { Box, Button, CircularProgress, Link, TextField, Typography } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { GridCheckCircleIcon } from '@material-ui/data-grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { IStateServers } from '@models/state-servers';
import { clearGeocode, geocode } from '@redux/actions/serversActions';
import appLangs from '@utils/app-languages';
import { LANGUAGE_JAPANESE } from '@utils/common';
import { convertWGS84toBD09 } from '@utils/convert-coordinates';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

type Props = {
  mapAddress: string;
  latitude: number;
  longitude: number;
  language: string;
  isBaidu: boolean;
  servers: IStateServers;
  updateLocationInfo: (value: any) => void;
  geocode: (params: { address: string; lang?: string }) => void;
  clearGeocode: () => void;
  errors: {
    mapAddress: boolean;
    latitude: boolean;
    longitude: boolean;
  };
};

type ManualCoordinateInputResult = {
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
};

type State = {
  hoveredIdx: number | null;
  selectedIdx: number | null;
  searchCount: number;
  isEditing: boolean;
  manualCoordinateInputResults: ManualCoordinateInputResult[];
  isLatLngSearch: boolean;
};

class GeocodeAddressClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    mapAddress: '',
    latitude: 0,
    longitude: 0,
    isBaidu: false,
    language: LANGUAGE_JAPANESE,
    errors: {
      mapAddress: false,
      latitude: false,
      longitude: false,
    },
  };

  constructor(props: Props) {
    super(props);
    const { mapAddress } = props;
    const isEditing = !!mapAddress;
    const searchCount = Number(isEditing);
    this.state = {
      hoveredIdx: null,
      selectedIdx: null,
      searchCount,
      isEditing,
      manualCoordinateInputResults: [],
      isLatLngSearch: false,
    };
  }

  componentDidMount() {
    const { mapAddress, latitude, longitude, updateLocationInfo, clearGeocode } = this.props;
    const { isEditing } = this.state;
    clearGeocode();
    if (isEditing) {
      updateLocationInfo({ mapAddress, latitude, longitude, isMapSelected: true });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { mapAddress, updateLocationInfo } = this.props;
    const { selectedIdx } = this.state;
    if (prevProps.mapAddress !== mapAddress && selectedIdx !== null) {
      const selectedResult = this.getCurrentResults()?.[selectedIdx];
      if (!selectedResult || selectedResult.formatted_address !== mapAddress) {
        this.setState({ selectedIdx: null });
        updateLocationInfo({ mapAddress, isMapSelected: false });
      }
    }
  }

  private toDecimalString(value: number): string {
    return value.toFixed(5);
  }

  private isLatLngFormat = (address: string) => {
    return /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/.test(address.trim());
  };

  private createManualCoordinateInputResult = (lat: number, lng: number) => {
    return {
      formatted_address: `${this.toDecimalString(lat)}, ${this.toDecimalString(lng)}`,
      geometry: { location: { lat, lng } },
    };
  };

  private getLat = (result: google.maps.GeocoderResult | ManualCoordinateInputResult): number => {
    const latValue = result.geometry?.location.lat;
    return typeof latValue === 'function' ? latValue() : latValue || 0;
  };

  private getLng = (result: google.maps.GeocoderResult | ManualCoordinateInputResult): number => {
    const lngValue = result.geometry?.location.lng;
    return typeof lngValue === 'function' ? lngValue() : lngValue || 0;
  };

  private handleSearch = () => {
    const { geocode, language: lang, mapAddress: address, clearGeocode } = this.props;
    if (!address) return;
    this.setState((prevState) => ({ searchCount: prevState.searchCount + 1 }));
    if (this.isLatLngFormat(address)) {
      clearGeocode();
      const [latStr, lngStr] = address.split(',');
      const lat = parseFloat(latStr.trim());
      const lng = parseFloat(lngStr.trim());
      const manualCoordinateInputResult = this.createManualCoordinateInputResult(lat, lng);
      this.setState({
        manualCoordinateInputResults: [manualCoordinateInputResult],
        isLatLngSearch: true,
        selectedIdx: null,
      });
    } else {
      this.setState({
        manualCoordinateInputResults: [],
        isLatLngSearch: false,
      });
      geocode({ address, lang });
    }
  };

  private handleSelectAddress = (
    result: google.maps.GeocoderResult | ManualCoordinateInputResult,
    idx: number,
  ) => {
    const { updateLocationInfo } = this.props;
    const latitude = this.getLat(result);
    const longitude = this.getLng(result);
    const mapAddress = this.state.isLatLngSearch
      ? `${this.toDecimalString(latitude)}, ${this.toDecimalString(longitude)}`
      : result.formatted_address;

    this.setState({ selectedIdx: idx, hoveredIdx: null });
    updateLocationInfo({
      mapAddress,
      latitude,
      longitude,
      isMapSelected: true,
      errors: { mapAddress: false, latitude: false, longitude: false },
    });
  };

  private makeGoogleMapLink = (lat: number, lng: number): string => {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  };

  private makeBaiduMapLink = (lat: number, lng: number): string => {
    const coordinates = convertWGS84toBD09(lat, lng);
    const { lat: bdLat, lon: bdLng } = coordinates;

    return `http://api.map.baidu.com/marker?location=${bdLat},${bdLng}&title=Location&output=html`;
  };

  private mapLinkComponent = (lat: number, lng: number) => {
    const { isBaidu } = this.props;
    const href = isBaidu ? this.makeBaiduMapLink(lat, lng) : this.makeGoogleMapLink(lat, lng);
    const mapName = isBaidu ? 'Baidu' : 'Google';

    return (
      <Typography variant='body2' color='textSecondary'>
        <Link href={href} target='_blank' rel='noopener'>
          {mapName} Maps
          <OpenInNewIcon
            fontSize='small'
            style={{ marginLeft: '4px', marginTop: '-12px', marginBottom: '-4px' }}
          />
        </Link>
      </Typography>
    );
  };

  private addressInputComponent = () => {
    const { mapAddress, errors, language, updateLocationInfo, servers } = this.props;
    const isRequesting = servers.isRequesting;

    return (
      <Box display='flex' alignItems='center'>
        <Box flex='1'>
          <TextField
            label={`${appLangs.mapAddress[language]} (${appLangs.require[language]})`}
            value={mapAddress}
            onChange={(e) => updateLocationInfo({ mapAddress: e.target.value })}
            fullWidth
            error={Object.values(errors).some(Boolean)}
          />
        </Box>
        <Box ml={1} marginTop={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={this.handleSearch}
            disabled={isRequesting}
          >
            {isRequesting ? <CircularProgress size={20} /> : appLangs.search[language]}
          </Button>
        </Box>
      </Box>
    );
  };

  private searchResultComponent = (
    result: google.maps.GeocoderResult | ManualCoordinateInputResult,
    idx: number,
  ) => {
    const { hoveredIdx, selectedIdx, isLatLngSearch } = this.state;
    const isHovered = hoveredIdx === idx;
    const isSelected = selectedIdx === idx;
    const backgroundColor = isHovered || isSelected ? '#e3f2fd' : 'transparent';
    const lat = this.getLat(result);
    const lng = this.getLng(result);
    const latDir = lat >= 0 ? 'N' : 'S';
    const lngDir = lng >= 0 ? 'E' : 'W';
    const absLat = this.toDecimalString(Math.abs(lat));
    const absLng = this.toDecimalString(Math.abs(lng));
    const displayAddress = isLatLngSearch
      ? `${absLat} ${latDir}, ${absLng} ${lngDir}`
      : result.formatted_address;

    return (
      <Box
        key={idx}
        mb={2}
        p={1}
        border='1px solid #ccc'
        borderRadius={4}
        position='relative'
        style={{ cursor: 'pointer', backgroundColor }}
        onMouseEnter={() => this.setState({ hoveredIdx: idx })}
        onMouseLeave={() => this.setState({ hoveredIdx: null })}
        onClick={() => this.handleSelectAddress(result as google.maps.GeocoderResult, idx)}
      >
        <Typography variant='body1'>{displayAddress}</Typography>
        {this.mapLinkComponent(this.getLat(result), this.getLng(result))}
        {isSelected && (
          <Box style={{ position: 'absolute', bottom: 2, right: 8 }}>
            <GridCheckCircleIcon color='primary' />
          </Box>
        )}
      </Box>
    );
  };

  private getCurrentResults = (): Array<google.maps.GeocoderResult | ManualCoordinateInputResult> => {
    const { servers } = this.props;
    const { manualCoordinateInputResults, isLatLngSearch } = this.state;
    return isLatLngSearch ? manualCoordinateInputResults : servers?.geocodeResults || [];
  };

  private searchResultsComponent = () => {
    const { servers, language } = this.props;
    const { selectedIdx } = this.state;
    const isRequesting = servers.isRequesting;
    if (isRequesting) return <CircularProgress size={24} />;
    const results = this.getCurrentResults();

    return (
      <>
        {results?.map((result, idx: number) => {
          return this.searchResultComponent(result, idx);
        })}
        {results.length > 0 && selectedIdx === null && (
          <Typography variant='body2' color='secondary' style={{ marginBottom: '4px' }}>
            {appLangs.mapAddressSelection[language]}
          </Typography>
        )}
      </>
    );
  };

  render() {
    const { servers, language, mapAddress, latitude, longitude } = this.props;
    const { searchCount, isEditing } = this.state;
    const isRequesting = servers.isRequesting;
    const showInstruction = (!isEditing && searchCount === 0) || (isEditing && searchCount === 1);
    const showMapLink = isEditing && searchCount === 1 && mapAddress;
    const showNoResults =
      !isRequesting &&
      ((!isEditing && searchCount > 0) || (isEditing && searchCount > 1)) &&
      (!servers.geocodeResults || servers.geocodeResults.length === 0) &&
      this.getCurrentResults().length === 0;

    return (
      <Box>
        {this.addressInputComponent()}
        {showInstruction && (
          <Typography variant='body2' color='secondary'>
            {appLangs.mapAddressSearchInstruction[language]}
          </Typography>
        )}
        {showMapLink && this.mapLinkComponent(latitude, longitude)}
        {showNoResults && (
          <Typography variant='body2' color='secondary'>
            {appLangs.mapNoSearchResults[language]}
          </Typography>
        )}
        <Box mt={2}>{this.searchResultsComponent()}</Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  servers: state.servers,
});

const mapDispatchToProps = {
  geocode,
  clearGeocode,
};

const myStyles = (): StyleRules => ({});

export const GeocodeAddress = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(GeocodeAddressClass);
