// https://github.com/Kamapcuc/BaiduWGS84/blob/master/geoConverter.js

interface Coordinates {
  lat: number;
  lon: number;
}

const PI = Math.PI;
const X_PI = (PI * 3000.0) / 180.0;
const A = 6378245.0;
const EE = 0.00669342162296594323;

const convertWGS84toGCJ02 = (lat: number, lon: number): Coordinates => {
  const dLat = transformLat(lon - 105.0, lat - 35.0);
  const dLon = transformLon(lon - 105.0, lat - 35.0);
  const radLat = (lat / 180.0) * PI;
  const sinRadLat = Math.sin(radLat);
  const geoh = 1 - EE * sinRadLat * sinRadLat;
  const h = Math.sqrt(geoh);
  const adjustedLat = (dLat * 180.0) / (((A * (1 - EE)) / (geoh * h)) * PI);
  const adjustedLon = (dLon * 180.0) / ((A / h) * Math.cos(radLat) * PI);

  return {
    lat: lat + adjustedLat,
    lon: lon + adjustedLon,
  };
};

const transformLat = (x: number, y: number): number => {
  let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
  ret += ((20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0) / 3.0;
  ret += ((20.0 * Math.sin(y * PI) + 40.0 * Math.sin((y / 3.0) * PI)) * 2.0) / 3.0;
  ret += ((160.0 * Math.sin((y / 12.0) * PI) + 320.0 * Math.sin((y * PI) / 30.0)) * 2.0) / 3.0;

  return ret;
};

const transformLon = (x: number, y: number): number => {
  let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
  ret += ((20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0) / 3.0;
  ret += ((20.0 * Math.sin(x * PI) + 40.0 * Math.sin((x / 3.0) * PI)) * 2.0) / 3.0;
  ret += ((150.0 * Math.sin((x / 12.0) * PI) + 300.0 * Math.sin((x / 30.0) * PI)) * 2.0) / 3.0;

  return ret;
};

const convertGCJ02toBD09 = (lat: number, lon: number): Coordinates => {
  const R = Math.sqrt(lon * lon + lat * lat) + 0.00002 * Math.sin(lat * X_PI);
  const theta = Math.atan2(lat, lon) + 0.000003 * Math.cos(lon * X_PI);

  return {
    lat: R * Math.sin(theta) + 0.006,
    lon: R * Math.cos(theta) + 0.0065,
  };
};

const convertWGS84toBD09 = (lat: number, lon: number): Coordinates => {
  const pointGCJ02 = convertWGS84toGCJ02(lat, lon);

  return convertGCJ02toBD09(pointGCJ02.lat, pointGCJ02.lon);
};

export { convertWGS84toBD09 };
